<template>
  <div v-if="!loginHide" class="background-wrapper yb-view">
    <div class="login-pane" :class="isNonMobileScreen ? 'yb-view-left h-[100vh]' : 'yb-view-content'">
      <div class="py-4 tall:py-8 px-0 yb-view">
        <div class="yb-view-header pt-4 tall:pt-8">
          <div class="h-12 tall:h-20 svg-target">
            <svg-logo-stacked />
          </div>
        </div>
        <div class="yb-view-content-auto">
          <NuxtPage @login-hide="loginHide = true" />
        </div>
      </div>
    </div>

    <div v-if="isNonMobileScreen" ref="backgroundContainer" id="background-container" class="relative yb-view-content" @click="play">
      <div id="background-gradient" />

      <div v-if="loginBannerMessage" class="absolute z-5 left-0 right-0 bottom-0">
        <div class="m-10 p-5 border-2 border-yb-gray-alt-dark shadow-xl dark:border-none rounded bg-white bg-opacity-80 dark:bg-yb-gray-medium">
          <yb-markdown data-testid="login-banner-markdown-content" :source="loginBannerMessage" />
        </div>
      </div>

      <transition name="fade">
        <div v-show="playing" id="asteroids" ref="game" class="background-game" />
      </transition>

      <transition name="fade">
        <div v-show="!playing" class="absolute z-5" style="top: 20%; left: 10%">
          <template v-for="(slogan, index) in slogans" :key="index">
            <transition-group name="slogan-fade" tag="div" class="absolute top-0">
              <template v-for="(word, windex) in slogan" :key="windex">
                <div v-show="nextSlogan == index" class="relative left-0">
                  <h2 class="slogan">
                    {{ word }}
                  </h2>
                  <br>
                </div>
              </template>
            </transition-group>
          </template>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { useMediaQuery } from '@vueuse/core'
import particlesJS from 'particles.js-es'
import YbMarkdown from '@shared/components/YbMarkdown.vue'
import SvgLogoStacked from '@shared/assets/svg/yellowbrick-logo-stacked-gray.svg'
import { emitter } from '@/services/emitter'

/*
 * NB: we load as raw and use v-html because the svgo process normalizes
 * embedded id= tags to "a", "b", "c" and then collide.  This svg is complex
 * with a gradient that gets broken that way
 */
import particlesJSConfig from './particlesjs-config.json'

const slogans = [
["Your", "Data", "Anywhere"],
["Supercharge", "Your", "Data", "Cloud"],
]

export default {
  components: {
    YbMarkdown,
    SvgLogoStacked
  },
  props: {
    disableRouter: Boolean
  },
  setup() {
    const isNonMobileScreen = useMediaQuery('(min-width:768px)')
    return {
      isNonMobileScreen
    }
  },
  data() {
    return {
      playing: false,
      gameover: false,
      slogans,
      nextSlogan: 0,
      loginBannerMessage: '',
      loginHide: false
    }
  },
  created() {
    emitter.on('loginBannerMessage', (v) => {
      this.loginBannerMessage = v
    })
  },
  mounted() {
    this.loginHide = false
    if (this.$refs.backgroundContainer) {
      particlesJS.init('background-container', particlesJSConfig)
    }
    this.init()
  },
  activated() {
    this.init()
  },
  deactivated() {
    this.destroy()
  },
  beforeUnmount() {
    this.destroy()
  },
  methods: {
    showLoginBannerMessage(message) {
      this.loginBannerMessage = message
    },
    init() {
      this.interval = window.setInterval(() => {
        if (++this.nextSlogan >= slogans.length) {
          this.nextSlogan = 0
        }
      }, 30 * 1000)
    },
    destroy() {
      if (this.interval) {
        window.clearInterval(this.interval)
        delete this.interval
      }
    },
    async play() {
      if (this.disableRouter) {
        return
      }
      function cleanup(that) {
        if (that.asteroids) {
          that.asteroids.pulse && window.clearInterval(that.asteroids.pulse)
          that.asteroids = null
        }
      }
      this.playing = !this.playing
      if (!this.asteroids) {
        const AsteroidsModule = await import('../util/asteroids')
        const Asteroids = AsteroidsModule.default
        this.asteroids = new Asteroids(this.$refs.game)
        const originalGameOver = this.asteroids.gameOver
        this.gameover = false
        const that = this
        this.asteroids.gameOver = () => {
          if (!that.gameover) {
            that.gameover = true
            if (!that.playing) {
              cleanup(that)
            } else {
              originalGameOver()
            }
          }
        }
      } else if (this.playing) {
        this.asteroids.level.levelUp(this.asteroids)
      } else if (this.gameover) {
        cleanup(this)
      }
    }
  }
}
</script>

<style scoped lang="postcss">

.background-wrapper {
  min-height: 100vh;
  max-height: 100vh;
  height: 100vh;
}

#background-gradient {
  @apply absolute z-0;
  background: linear-gradient(135deg, #FFCD32, #86BC4D, #FFCD32, #55ACBA, #FFCD32, #1F4471, #FFCD32, #55ACBA, #FFCD32, #8E2E63, #FFCD32, #F29C11, #FFCD32, #412250, #FFCD32, #55ACBA, #FFCD32, #D34A24);
  width: 2000%;
  height: 2000%;
  animation: gradient 1800s ease infinite;
}

@keyframes gradient {
  0% {
    transform: translate(0%, 0%);
  }
  50% {
    transform: translate(-90%, -90%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}

#background-container :deep(canvas) {
  @apply absolute;
}

.background-game {
  @apply fixed bg-yb-brand-primary-dark ring-1 overflow-hidden bg-opacity-80 transform sm:scale-50 md:scale-75 lg:scale-100 xl:scale-125 2xl:scale-150;
  width: 640px;
  height: 480px;
  top: 25%;
  left: calc(50% - 150px);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.slogan-fade-enter-active, .slogan-fade-leave-active {
  transition: opacity 4s;
}
.slogan-fade-enter-from, .slogan-fade-leave-to {
  opacity: 0;
}

.login-pane {
  @apply md:w-[500px] md:min-w-[500px] md:max-w-[500px] xl:w-[600px] xl:min-w-[600px] xl:max-w-[600px] flex flex-col;
}

div :deep(.svg-target) > svg {
  @apply h-full w-full;
}

div :deep(.yb-button-invert-xl) {
  @apply !text-yb-gray-medium;
}

.slogan {
  @apply bg-yb-gray-medium text-white mb-4 p-4 font-semibold tracking-[-0.05em] xl:tracking-[-0.06em] text-[3rem] xl:text-[4rem];
  line-height: 75%;
  padding-bottom: -8em;
}

</style>
